import { createElement, HTMLAttributes } from 'react'
import cx from 'classnames'
import { getLineClamp, Truncate, ColorCode, getTextColor } from 'utils/ui'
import { FontSize, getFontSize } from 'utils/typography'

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  noSize?: boolean
  truncate?: Truncate
  v2Size?: FontSize
  v2TextColor?: ColorCode
}

function deprecatedGetFontSize(as: HeadingProps['as']) {
  if (as === 'h1') {
    return 'text-[40px]'
  }

  if (as === 'h2') {
    return 'text-[32px]'
  }

  if (as === 'h3') {
    return 'text-[28px]'
  }

  if (as === 'h4') {
    return 'text-2xl'
  }

  if (as === 'h5') {
    return 'text-xl'
  }

  if (as === 'h6') {
    return 'text-lg'
  }

  return 'text-base'
}

export const Heading: React.FC<HeadingProps> = (props) => {
  const {
    className,
    noSize,
    v2TextColor,
    v2Size,
    truncate,
    as = 'h2',
    ...rest
  } = props
  const classNames = [
    'leading-snug font-semibold',
    v2TextColor ? getTextColor(v2TextColor) : 'text-gray-1100',
    noSize || v2Size ? undefined : deprecatedGetFontSize(as),
    v2Size ? getFontSize(v2Size) : undefined,
  ]
  if (truncate) {
    classNames.push(
      typeof truncate === 'boolean' ? 'truncate' : getLineClamp(truncate),
    )
  }

  return createElement(as, {
    className: cx(className, classNames),
    ...rest,
  })
}
