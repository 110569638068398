import cx from 'classnames'
import { Button } from '../Button'
import { Text, TextProps } from '../Text'

interface Props {
  message: React.ReactNode
  MessageProps?: TextProps
  illustration: React.ReactNode
  className?: string
  buttonText?: string
  onClick?: () => void
}

export function Empty(props: Props) {
  const {
    illustration,
    message,
    className,
    onClick,
    buttonText,
    MessageProps,
  } = props

  return (
    <div className={cx('flex flex-col items-center text-center', className)}>
      {illustration}
      <Text
        textColor="800"
        className="mb-7 whitespace-pre-line"
        {...MessageProps}
      >
        {message}
      </Text>
      {onClick && (
        <Button isRounded fullWidth onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
