import { useAuthContext } from 'context/auth'
import { WithChildren } from 'types/common'
import cx from 'classnames'
import { AppSkeleton } from '../AppSkeleton'

interface LayoutProps extends WithChildren {
  headerRender?: React.ReactNode
  footerRender?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  isPublic?: boolean
}

export const Layout = (props: LayoutProps) => {
  const {
    children,
    footerRender,
    headerRender,
    isPublic = false,
    className,
    ...otherProps
  } = props
  const { isLoading } = useAuthContext()

  if (isLoading && !isPublic) {
    return <AppSkeleton />
  }

  return (
    <>
      {headerRender}
      <main
        {...otherProps}
        className={cx('bg-blue-10 sm:container sm:min-h-screen', className)}
      >
        {children}
      </main>
      {footerRender}
    </>
  )
}
