import { forwardRef } from 'react'
import { IconLoadMoreSpinner } from 'components/icons/components/IconLoadMoreSpinner'
import { Text } from 'components/ui/Text'
import { useTranslations } from 'next-intl'

export const LoadMore = forwardRef<HTMLDivElement>((_, ref) => {
  const t = useTranslations()
  return (
    <div
      ref={ref}
      className="w-full flex flex-col items-center justify-center p-4"
    >
      <IconLoadMoreSpinner className="mb-2" />
      <Text className="text-xs !text-primary">{t('loading')}</Text>
    </div>
  )
})
