import { Skeleton } from 'components/ui/Skeleton'

export function AppSkeleton() {
  return (
    <div className="absolute sm:container inset-0 bg-white">
      <div className="h-[72px] px-4 shadow-300 flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <Skeleton className="w-10 h-10 rounded-xl" />
          <Skeleton className="w-20 h-6 rounded-lg opacity-75" />
        </div>
        <Skeleton className="w-10 h-10 rounded-full opacity-50" />
      </div>
      <div className="p-4 space-x-3 flex">
        <Skeleton className="w-20 h-32 rounded-xl" />
        <Skeleton className="w-20 h-32 rounded-xl opacity-75" />
        <Skeleton className="w-20 h-32 rounded-xl opacity-50" />
      </div>
      <div className="p-4 space-y-2">
        <Skeleton className="w-52 h-8 rounded-lg" />
        <Skeleton className="w-32 h-6 rounded-lg" />
      </div>
      <div className="h-16 border-t border-gray-400 space-x-3 items-center flex px-4 absolute bottom-0 inset-x-0">
        <Skeleton className="w-11 h-11 rounded-xl" />
        <Skeleton className="w-11 h-11 rounded-xl opacity-75" />
        <Skeleton className="w-11 h-11 rounded-xl opacity-50" />
      </div>
    </div>
  )
}
